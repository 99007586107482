import React, { useEffect, useState } from "react";
import { WebPagesIcon } from "assets/images";
import { Button, Input } from "components";
import {
  Book,
  BookOpen,
  Captions,
  Check,
  ChevronDown,
  ChevronRight,
  Copy,
  Download,
  Edit,
  File,
  Loader2,
  Plus,
  PlusIcon,
  RefreshCcw,
  Trash2,
  Upload,
  X,
} from "lucide-react";
import { formatFileSize, getToken } from "shared/resources";
// import axios from "axios";
// import { routes } from "api/routes";
import useGetKnowledgeBaseList from "hooks/useGetKnowledgeBaseList";
import { toastEmitter } from "components/Toast";
import { routes } from "api/routes";
import useDeleteKnowledgeBase from "hooks/useDeleteKnowledgeBase";
import useDeleteFileInKnowledgeBase from "hooks/useDeleteFileInKnowledgeBase";
import Pdf from "assets/images/Pdf";
import jsPDF from "jspdf";

function KnowledgeBased() {
  const [showModal, setShowModal] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [addTextModal, setAddTextModal] = useState(false);
  const [addWebModal, setAddWebModal] = useState(false);
  const {
    mutateAsync: deleteKnowledgeBaseMutate,
    isPending: deleteKnowledgeBasePending,
  } = useDeleteKnowledgeBase();
  const { mutateAsync: deleteFileMutate } = useDeleteFileInKnowledgeBase();
  const [currentKB, setCurrentKB] = useState(null);
  const [kbName, setKbName] = useState("");
  const [fileName, setFileName] = useState("");
  const [textContent, setTextContent] = useState("");
  const [uploads, setUploads] = useState([]);
  const [webUrl, setWebUrl] = useState("");
  const [siteMapModal, setSiteMapModal] = useState(false);
  const [autoSyncUrls, setAutoSyncUrls] = useState(false);
  const [showSite, setShowSite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState({});

  const [deletingFiles, setDeletingFiles] = useState({});
  const [selectedItems, setSelectedItems] = useState({
    all: false,
    root: false,
    subItem: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const {
    data: knowledgeData,
    mutate,
    isPending: getKnowledgeBasePending,
  } = useGetKnowledgeBaseList();
  const knowledgeBaseList = knowledgeData?.data?.data || [];
  const hasUrlUploads = uploads.some((item) => item.type === "url");
  const hasUrlUploadsInKb = currentKB?.items?.some(
    (item) => item.type === "url"
  );
  const toggleShowSite = () => {
    setShowSite(!showSite);
  };

  useEffect(() => {
    if (knowledgeBaseList.length > 0) {
      const sortedList = [...knowledgeBaseList].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setCurrentKB(sortedList[0]);
    }
  }, [knowledgeBaseList]);

  useEffect(() => {
    mutate({
      page: 1,
      limit: 10,
      search: "",
    });
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
    setShowModal("editKnowledge");
    setKbName(currentKB.name);

    const existingUploads = currentKB.file_urls.map((file) => ({
      name: file.name,
      type: "application/pdf",
      size: file.size,
      url: file.url,
    }));
    setUploads(existingUploads);
    setOriginalData({
      name: currentKB.name,
      files: existingUploads,
    });
  };

  const checkForChanges = () => {
    if (!originalData) return false;

    const nameChanged = kbName !== originalData.name;
    const filesChanged =
      uploads.length !== originalData.files.length ||
      JSON.stringify(uploads.map((u) => u.url)) !==
        JSON.stringify(originalData.files.map((f) => f.url));

    setHasChanges(nameChanged || filesChanged);
  };

  useEffect(() => {
    if (isEditing) {
      checkForChanges();
    }
  }, [kbName, uploads, isEditing]);

  const handleSelectAll = () => {
    const newValue = !selectedItems.all;
    setSelectedItems({
      all: newValue,
      root: newValue,
      subItem: newValue,
    });
  };

  const handleSelectRoot = () => {
    const newValue = !selectedItems.root;
    setSelectedItems((prev) => ({
      ...prev,
      root: newValue,
      subItem: newValue,
      all: newValue && prev.all,
    }));
  };

  const handleSelectSubItem = () => {
    const newValue = !selectedItems.subItem;
    setSelectedItems((prev) => ({
      ...prev,
      subItem: newValue,
      all: newValue && prev.root,
      root: prev.root,
    }));
  };

  const getSelectedCount = () => {
    let count = 0;
    if (selectedItems.root) count++;
    if (selectedItems.subItem) count++;
    return count;
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const pdfFiles = files.filter((file) => file.type === "application/pdf");

    if (pdfFiles.length !== files.length) {
      toastEmitter("error", "Only PDF files are allowed");
      return;
    }

    const newUploads = pdfFiles.map((file) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      file,
    }));

    setUploads((prevUploads) => [...prevUploads, ...newUploads]);
  };

  const handleSaveKB = async () => {
    if (!kbName) {
      toastEmitter(
        "error",
        "Please provide a name and upload at least one PDF file"
      );
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", kbName);

      uploads.forEach((upload) => {
        // Preserve the original filename when appending to FormData
        formData.append("files", upload.file, upload.name);
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}${routes.KNOWLEDGEBASE_CREATE.URL}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create knowledge base");
      }

      toastEmitter("success", "Knowledge base created successfully");
      setShowModal("");
      setKbName("");
      setUploads([]);
      mutate();
    } catch (error) {
      toastEmitter("error", "Error creating knowledge base: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveText = () => {
    if (fileName && textContent) {
      try {
        const doc = new jsPDF();

        doc.setFontSize(12);
        const textLines = doc.splitTextToSize(textContent, 180);
        doc.text(textLines, 15, 15);

        const fileNameWithExtension = fileName.toLowerCase().endsWith(".pdf")
          ? fileName
          : `${fileName}.pdf`;

        const pdfBlob = doc.output("blob");

        const pdfUrl = URL.createObjectURL(pdfBlob);

        setUploads((prevUploads) => [
          {
            name: fileNameWithExtension,
            type: "application/pdf",
            size: pdfBlob.size,
            file: pdfBlob,
          },
          ...prevUploads,
        ]);

        URL.revokeObjectURL(pdfUrl);

        setFileName("");
        setTextContent("");
        setAddTextModal(false);

        toastEmitter("success", `Created PDF: ${fileNameWithExtension}`);
      } catch (error) {
        console.error("Error creating PDF:", error);
        toastEmitter("error", "Failed to create PDF file");
      }
    } else {
      toastEmitter("error", "Please provide both filename and content");
    }
  };
  const handleSaveUrl = () => {
    if (webUrl) {
      setUploads((prevUploads) => [
        { name: webUrl, type: "url" },
        ...prevUploads,
      ]);
      setWebUrl("");
      setAddWebModal(false);
      setSiteMapModal(true);
    }
  };

  const removeUploads = (index) => {
    setUploads(uploads.filter((_, i) => i !== index));
  };

  useEffect(() => {
    localStorage.setItem("uploads", JSON.stringify(uploads));
  }, [uploads]);

  useEffect(() => {
    const savedUploads = localStorage.getItem("uploads");
    if (savedUploads) {
      setUploads(JSON.parse(savedUploads));
    }
  }, []);

  const handleDelete = async () => {
    await deleteKnowledgeBaseMutate({ uuid: currentKB.uuid });
    setShowModal(null);
    mutate({
      page: 1,
      limit: 1000,
      search: "",
    });
  };

  const handleModalClose = () => {
    setShowModal(null);
    if (isEditing) {
      setIsEditing(false);
      setKbName("");
      setUploads([]);
      setOriginalData(null);
      setHasChanges(false);
    }
  };
  const handleAddClick = () => {
    setIsEditing(false);
    setShowModal("addKnowledge");
    setKbName("");
    setUploads([]);
    setOriginalData(null);
    setHasChanges(false);
  };
  const handleUpdate = async () => {
    if (!hasChanges) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", kbName);
      formData.append("uuid", currentKB.uuid);

      const filesToUpload = uploads.filter((upload) => upload.file);
      filesToUpload.forEach((upload) => {
        // Preserve the original filename when updating
        formData.append("files", upload.file, upload.name);
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}${routes.KNOWLEDGEBASE_UPDATE.URL}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update knowledge base");
      }

      toastEmitter("success", "Knowledge base updated successfully");
      handleModalClose();
      mutate({
        page: 1,
        limit: 10,
        search: "",
      });
    } catch (error) {
      toastEmitter("error", "Error updating knowledge base: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadFile = async (fileUrl, uuid) => {
    setDownloading((prev) => ({ ...prev, [fileUrl]: true }));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_API_URL}${routes.KNOWLEDGEBASE_DOWNLOAD.URL}`,
        {
          method: "POST",
          body: JSON.stringify({
            uuid: uuid,
            url: fileUrl,
          }),
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toastEmitter("error", "Error downloading file: " + error.message);
    } finally {
      setDownloading((prev) => ({ ...prev, [fileUrl]: false }));
    }
  };

  const handleDeleteFile = async (index, fileName) => {
    // Set loading state for specific file
    setDeletingFiles((prev) => ({ ...prev, [fileName]: true }));

    try {
      if (currentKB.file_urls[index]) {
        await deleteFileMutate({
          uuid: currentKB.uuid,
          fileName: currentKB.file_urls[index].name,
        });
        mutate({
          page: 1,
          limit: 1000,
          search: "",
        });
        setUploads(uploads.filter((_, i) => i !== index));
      } else {
        removeUploads(index);
      }
    } catch (error) {
      toastEmitter("error", "Error deleting file: " + error.message);
    } finally {
      // Clear loading state for specific file
      setDeletingFiles((prev) => {
        const newState = { ...prev };
        delete newState[fileName];
        return newState;
      });
    }
  };

  return (
    <div className="flex relative">
      {/* Sidebar */}
      <div className="border-r-4 h-screen overflow-scroll p-4 border-[#D2D5DA] w-72 pt-6">
        <div className="flex gap-2 items-center justify-between pb-4 border-b border-[#D2D5DA]">
          <div className="flex gap-3 items-center">
            <Book size={16} />
            <p>Knowledge Base</p>
          </div>
          <button
            type="button"
            onClick={handleAddClick}
            className="w-[23px] h-[23px] font-bold rounded items-center flex justify-center bg-green"
          >
            <Plus color="white" />
          </button>
        </div>
        <ul className="flex gap-2 flex-col mt-5">
          {knowledgeBaseList.map((kb) => (
            <li
              key={kb.id}
              className={`p-2 hover:bg-grey-200 cursor-pointer flex items-center gap-4 rounded-md ${
                currentKB?.uuid === kb.uuid ? "bg-grey-200" : ""
              }`}
              onClick={() => setCurrentKB(kb)}
            >
              <BookOpen size={16} />
              <span>{kb.name}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Body/Main Content */}
      <div className="h-screen overflow-y-scroll p-6 w-full">
        {getKnowledgeBasePending ? (
          <div className="h-screen m-auto flex items-center justify-center flex-col gap-4 text-center">
            <Loader2 className="animate-spin" />{" "}
          </div>
        ) : knowledgeBaseList.length === 0 ? (
          <div className="h-screen m-auto flex items-center justify-center flex-col gap-4 text-center">
            <div className="flex items-center justify-center rounded-lg border border-black h-[60px] w-[40px]">
              <Book />
            </div>
            <p>You don&apos;t have any knowledge base.</p>
          </div>
        ) : currentKB ? (
          <div className="w-full">
            <div className="w-full justify-between flex items-center mb-6">
              <div className="flex flex-col gap-1">
                <p className="text-xl font-extrabold">{currentKB.name}</p>
                <div className="flex items-center gap-2">
                  <p className="text-[11px] text-grey-700 flex gap-1 items-center">
                    <span>ID; {currentKB?.uuid.substring(0, 20)}...</span>
                    <Copy size={10} />
                  </p>
                  <Check color="green" size={10} className="font-bold" />
                  <p className="text-[11px] text-grey-700">
                    Uploaded By:{" "}
                    {new Date(currentKB.created_at).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 items-center">
                {hasUrlUploadsInKb && (
                  <div className="w-[150px]">
                    <Button extraClasses="flex items-center gap-3">
                      <RefreshCcw />
                      <span>Re-sync</span>
                    </Button>
                  </div>
                )}
                <div className="w-[150px]">
                  <Button
                    onClick={handleEditClick}
                    extraClasses="flex items-center gap-3"
                  >
                    <Edit />
                    <span>Edit</span>
                  </Button>
                </div>
                <div
                  onClick={() => setShowModal("deleteKnowledge")}
                  className="bg-danger w-[50px] h-[50px] rounded-md items-center justify-center flex cursor-pointer"
                >
                  {deleteKnowledgeBasePending ? (
                    <Loader2 className="animate-spin" />
                  ) : (
                    <Trash2 color="white" />
                  )}
                </div>
              </div>
            </div>
            <ul className="mt-6 w-full flex-col gap-4 flex">
              {currentKB.file_urls?.map((file, index) => (
                <li
                  key={index}
                  className="w-full flex justify-between items-center h-full py-2 rounded-md px-4 border"
                >
                  <div className="flex gap-4 items-center">
                    {/* {file?.url ? ( */}
                    <span>
                      <Pdf />
                    </span>
                    {/* ) : ( */}
                    {/* <div className="relative">
                        <File size={40} />
                        <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-[#a87532] text-white text-[12px] font-bold">
                          txt
                        </p>
                      </div> */}

                    <div className="flex flex-col gap-[2px]">
                      <p className="text-base break-all">
                        {file?.name?.length > 60
                          ? `${file?.name?.substring(0, 60)}...`
                          : file?.name}
                      </p>
                      {file?.size && (
                        <span className="text-[12.5px] text-grey-700">
                          {formatFileSize(file.size)}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => handleDownloadFile(file.url, currentKB.uuid)}
                  >
                    {downloading[file?.url] ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <Download />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="h-screen m-auto flex items-center justify-center flex-col gap-4 text-center">
            <Loader2 className="animate-spin" />{" "}
          </div>
        )}
      </div>

      {(showModal === "addKnowledge" || showModal === "editKnowledge") && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setShowModal(null)}
        >
          <div
            className={`bg-white p-4 pb-2 rounded-lg w-full sm:w-[600px] ${
              uploads.length > 1 && "h-[470px]"
            } relative`}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              onClick={() => setShowModal(null)}
              className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
            >
              <X className="w-4 h-4" />
            </div>
            <p className="text-lg">
              {isEditing ? "Edit Knowledge Base" : "Add a Knowledge Base"}
            </p>
            <div className="mt-5 mb-4 flex flex-col gap-4 relativ">
              <div
                className={` flex flex-col gap-2 ${
                  uploads.length > 1 && "h-[217px] overflow-y-scroll"
                } reltive`}
              >
                <label>Knowledge base name</label>
                <Input
                  placeholder="Enter"
                  value={kbName}
                  onChange={(e) => setKbName(e.target.value)}
                />
                <p>Documents</p>
                <ul className="mb-2 flex flex-col gap-3">
                  {uploads.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between w-full h-[55px] rounded-md px-4 border"
                    >
                      <div className="flex gap-4 items-center">
                        <span>
                          {item?.type === "text/plain" ||
                          item?.name?.endsWith(".txt") ? (
                            <div className="relative">
                              <File size={40} />
                              <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-[#a87532] text-white text-[12px] font-bold">
                                txt
                              </p>
                            </div>
                          ) : (
                            <span>
                              <Pdf />
                            </span>
                          )}
                        </span>
                        <div className="flex flex-col gap-[2px]">
                          <p>
                            {item?.name?.length > 40
                              ? `${item?.name?.substring(0, 40)}...`
                              : item.name}
                          </p>
                          {item?.size &&
                            !item?.type.includes("text") &&
                            !item?.type.includes("url") && (
                              <p className="text-[12.5px] text-grey-700">
                                {formatFileSize(item.size)}
                              </p>
                            )}
                        </div>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          if (isEditing && currentKB.file_urls[index]) {
                            handleDeleteFile(index, item.name);
                          } else {
                            removeUploads(index);
                          }
                        }}
                      >
                        {deletingFiles[item?.name] ? (
                          <Loader2 className="animate-spin" />
                        ) : (
                          <Trash2 />
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative">
                <button
                  type="button"
                  onClick={() => setShowDropDown(!showDropDown)}
                  className="border border-black w-[90px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-green hover:text-white duration-300"
                >
                  <PlusIcon size={18} />
                  <span>Add</span>
                </button>
                {showDropDown && (
                  <div className="absolute bg-white z-50 top-10 rounded-md shadow-lg py-4 flex flex-col gap-3">
                    <div className="flex gap-3 items-center cursor-pointer hover:bg-grey-400 px-4 py-1 relative">
                      <div className="border border-[#D2D5DA66] bg-[#F5F5F5] rounded-full w-[35px] h-[35px] flex items-center justify-center">
                        <Upload size={15} />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="text-[14.5px]">Upload Files</p>
                        <p className="text-[12px] text-grey-600">
                          File size should be less than 100MB
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/bmp,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,message/rfc822,application/epub+zip,image/heic,text/html,image/jpeg,image/png,text/markdown,application/vnd.ms-outlook,application/vnd.oasis.opendocument.text,text/x-org,application/pkcs7-signature,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/x-rst,application/rtf,image/tiff,text/plain,text/tab-separated-values,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/xml"
                        multiple
                        className="opacity-0 cursor-pointer inset-0 absolute"
                        onChange={handleFileUpload}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setShowDropDown(false);
                        setAddTextModal(true);
                      }}
                      className="flex gap-3 items-center cursor-pointer hover:bg-grey-400 px-4 py-1"
                    >
                      <div className="border border-[#D2D5DA66] bg-[#F5F5F5] rounded-full w-[35px] h-[35px] flex items-center justify-center">
                        <Captions size={15} />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="text-[14.5px]">Add Text</p>
                        <p className="text-[12px] text-grey-600">
                          Texts created will be saved as PDFs
                        </p>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        // setShowDropDown(false);
                        // setAddWebModal(true);
                        toastEmitter(
                          "info",
                          "This feature is not available yet."
                        );
                      }}
                      className="flex gap-3 items-center cursor-pointer hover:bg-grey-400 px-4 py-1"
                    >
                      <div className="border border-[#D2D5DA66] bg-[#F5F5F5] rounded-full w-[35px] h-[35px] flex items-center justify-center">
                        <WebPagesIcon />
                      </div>
                      <div className="flex flex-col gap-1">
                        <p className="text-[14.5px]">Add web pages</p>
                        <p className="text-[12px] text-grey-600">
                          Scan and synchronize your website
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-3 items-center justify-end ml-auto">
              {hasUrlUploads && (
                <div className="flex items-center gap-2 mr-auto">
                  <input
                    type="checkbox"
                    id="autoSync"
                    checked={autoSyncUrls}
                    onChange={(e) => setAutoSyncUrls(e.target.checked)}
                    className="rounded"
                  />
                  <label htmlFor="autoSync" className="text-sm text-grey-700">
                    Auto sync all URLs
                  </label>
                </div>
              )}
              <button
                onClick={handleModalClose}
                className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
              >
                Cancel
              </button>
              <div className="w-[150px]">
                <Button
                  onClick={isEditing ? handleUpdate : handleSaveKB}
                  disabled={isEditing ? !hasChanges : !kbName}
                >
                  {loading ? (
                    <Loader2 className="animate-spin" />
                  ) : isEditing ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {addTextModal && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setAddTextModal(false)}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[600px] h-[400px] relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              onClick={() => setAddTextModal(false)}
              className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
            >
              <X className="w-4 h-4" />
            </div>
            <p className="text-lg">Add Text Content</p>
            <div className="mt-5 mb-4 flex flex-col gap-4">
              <label>File Name</label>
              <Input
                placeholder="Enter file name"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
              <label>Text Content</label>
              <textarea
                placeholder="Enter content"
                value={textContent}
                onChange={(e) => setTextContent(e.target.value)}
                className="border border-gray-300 rounded-lg p-2"
              />
            </div>
            <div className="flex gap-3 items-center justify-end ml-auto">
              <button
                onClick={() => setAddTextModal(false)}
                className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
              >
                Cancel
              </button>
              <div className="w-[150px]">
                <Button
                  onClick={handleSaveText}
                  disabled={!fileName || !textContent}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {addWebModal && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setAddWebModal(false)}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[600px] relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              onClick={() => setAddWebModal(false)}
              className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
            >
              <X className="w-4 h-4" />
            </div>
            <p className="text-lg">Add Web Pages</p>
            {/* Add your web pages form/content here */}
            <div className="mt-5 flex flex-col gap-4">
              <Input
                placeholder="Enter URL"
                value={webUrl}
                onChange={(e) => setWebUrl(e.target.value)}
              />
            </div>
            <div className="flex gap-3 items-center justify-end ml-auto mt-4">
              <button
                onClick={() => setAddWebModal(false)}
                className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
              >
                Cancel
              </button>
              <div className="w-[150px]">
                <Button onClick={handleSaveUrl} disabled={!webUrl}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {siteMapModal && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setSiteMapModal(false)}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[600px] h-[600px] overflow-y-scroll relative"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              onClick={() => setSiteMapModal(false)}
              className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
            >
              <X className="w-4 h-4" />
            </div>
            <p className="text-lg">Select Site Maps</p>
            <div className="flex w-full justify-between items-center mt-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedItems.all}
                  onChange={handleSelectAll}
                  className="rounded"
                />
                <p>Select All</p>
              </div>
              <p className="text-blue-light">Selected ({getSelectedCount()})</p>
            </div>
            <div className="flex items-center gap-3 mt-2">
              <div onClick={toggleShowSite}>
                {showSite ? <ChevronDown /> : <ChevronRight />}
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={selectedItems.root}
                  onChange={handleSelectRoot}
                  className="rounded"
                />
                <p>Select crunchy.com</p>
              </div>
            </div>
            {showSite && (
              <div className="flex items-center gap-2 pl-10 mt-2">
                <input
                  type="checkbox"
                  checked={selectedItems.subItem}
                  onChange={handleSelectSubItem}
                  className="rounded"
                />
                <p>https://crunchy.com</p>
              </div>
            )}
            <div className="absolute bottom-2 right-3 flex gap-3 items-center justify-end ml-auto mt-4">
              <button
                onClick={() => setSiteMapModal(false)}
                className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
              >
                Cancel
              </button>
              <div className="w-[150px]">
                <Button
                  onClick={() => setSiteMapModal(false)}
                  disabled={!selectedItems.root}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal === "deleteKnowledge" && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setShowModal(null)}
        >
          <div
            className={`bg-white p-4 pb-2 rounded-lg relative flex flex-col gap-4`}
            onClick={(e) => e.stopPropagation()}
          >
            <p className="font-bold text-xl">Delete Knowledge</p>
            <p className="text-grey-700 text-[15px]">
              Are you sure you want to delete this knowledge?
            </p>
            <div className="flex justify-end items-center ml-auto gap-3">
              <button
                onClick={() => setShowModal(null)}
                className="border border-black w-[100px] rounded-md h-[40px] flex gap-2 items-center justify-center duration-300"
              >
                Cancel
              </button>

              <button
                onClick={handleDelete}
                className=" w-[100px] rounded-md h-[40px] flex gap-2 items-center justify-center bg-danger text-white duration-300"
              >
                {deleteKnowledgeBasePending ? (
                  <Loader2 className="animate-spin" />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default KnowledgeBased;
