import { Call3D, X } from "assets/images";
import { Button, Spinner } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatPhoneNumber } from "shared/resources";

const RemoveForwardingModal = ({
  handleClose = () => null,
  deleteFn = () => null,
  isLoading = false,
  showModal = {},
}) => {
  const itemElement = showModal?.elementData ?? {};
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center  mt-8">
          <img alt="call-3d-icon" src={Call3D} className="m-auto w-16" />
        </div>
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          {t("overview.removeForwarding")}
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          Are you sure you want to remove <br />
          <span className="font-medium">
            {formatPhoneNumber(itemElement?.did_number)}&nbsp;
          </span>
          {t("overview.fromForwarding")}
        </div>
        <div className="mt-10 pb-14 flex items-center gap-2">
          <Button
            background="transparent"
            type="button"
            onClick={() => handleClose()}
            border="border-grey-400"
          >
            <div className="flex w-full px-5 justify-center items-center text-green-400">
              Cancel
            </div>
          </Button>
          <Button
            disabled={isLoading}
            background="danger"
            type="button"
            onClick={() =>
              deleteFn({
                uuid: itemElement?.uuid,
              })
            }
          >
            <div className="flex w-full px-5 justify-center items-center">
              {isLoading ? <Spinner /> : t("overview.remove")}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RemoveForwardingModal;
