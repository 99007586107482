import {
  DriverLicense,
  FilledCard,
  IdentyNumber,
  InfoGrey,
  Passport,
} from "assets/images";
import { Button, CountrySelect, Modal, Spinner } from "components";
import React, { useState, useEffect, useMemo } from "react";
import { DocumentUploadConstant, initialModalState } from "./constants";
import NationalIdentityModal from "./NationalIdentityModal";
import BankVerificationModal from "./BankVerificationModal";
import DriversLicenseModal from "./DriversLicenseModal";
import InternationalPassportModal from "./InternationalPassportModal";
import VotersCardModal from "./VotersCardModal";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useKYCData } from "hooks";
import { useTranslation } from "react-i18next";

const StepTwo = ({
  documentType,
  documentData,
  setCurrentStep = () => null,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (documentType && documentData && Object.keys(documentData).length > 0) {
      setShowModal({
        modalType: documentType,
        isShow: true,
        elementData: documentData,
      });
    }
  }, [documentType, documentData]);

  const handleDocumentUploadClick = ({ value = "" }) => {
    switch (value) {
      case DocumentUploadConstant.NATIONAL_IDENTITY:
      case DocumentUploadConstant.BANK_VERIFICATION:
      case DocumentUploadConstant.DRIVERS_LICENCE:
      case DocumentUploadConstant.INTERNATIONAL_PASSPORT:
      case DocumentUploadConstant.VOTERS_CARD:
        setShowModal({ modalType: value, isShow: true, elementData: {} });
        break;
      default:
        break;
    }
  };

  const ModalLookup = {
    [DocumentUploadConstant.NATIONAL_IDENTITY]: (
      <NationalIdentityModal
        showModal={showModal}
        handleClose={handleDocumentUploadModalClose}
      />
    ),
    [DocumentUploadConstant.BANK_VERIFICATION]: (
      <BankVerificationModal
        showModal={showModal}
        handleClose={handleDocumentUploadModalClose}
      />
    ),
    [DocumentUploadConstant.DRIVERS_LICENCE]: (
      <DriversLicenseModal
        showModal={showModal}
        handleClose={handleDocumentUploadModalClose}
      />
    ),
    [DocumentUploadConstant.INTERNATIONAL_PASSPORT]: (
      <InternationalPassportModal
        showModal={showModal}
        handleClose={handleDocumentUploadModalClose}
      />
    ),
    [DocumentUploadConstant.VOTERS_CARD]: (
      <VotersCardModal
        showModal={showModal}
        handleClose={handleDocumentUploadModalClose}
      />
    ),
  };

  function handleDocumentUploadModalClose() {
    setShowModal(initialModalState);
  }
  const { isShow, modalType } = showModal;

  return (
    <div className="w-full">
      <div className="w-full">
        <CountrySelect background="bg-grey-100" />
      </div>
      <div className="xl:mt-10 lg:mt-8 md:mt-5 w-full">
        <div className="text-xl font-semibold">{t("overview.selectDoc")}</div>
        <div className="text-grey-600 text-sm">{t("overview.validGov")}</div>
      </div>
      <div className="w-full flex flex-col gap-4 mt-4">
        <div className="flex justify-center items-center gap-4">
          <div
            className="border border-grey-400 rounded-md p-4 h-32 flex items-center justify-center flex-col w-full cursor-pointer"
            onClick={() =>
              handleDocumentUploadClick({
                value: DocumentUploadConstant.NATIONAL_IDENTITY,
              })
            }
          >
            <div className="w-12 h-11">
              <IdentyNumber />
            </div>
            <div className="font-medium">{t("overview.nin")}</div>
          </div>
          <div
            className="border border-grey-400 rounded-md p-4 h-32 flex items-center justify-center flex-col w-full cursor-pointer"
            onClick={() =>
              handleDocumentUploadClick({
                value: DocumentUploadConstant.BANK_VERIFICATION,
              })
            }
          >
            <div className="w-12 h-12">
              <FilledCard />
            </div>
            <div className="font-medium">{t("overview.bankVerification")}</div>
          </div>
          <div
            className="border border-grey-400 rounded-md p-4 h-32 flex items-center justify-center flex-col w-full cursor-pointer"
            onClick={() =>
              handleDocumentUploadClick({
                value: DocumentUploadConstant.DRIVERS_LICENCE,
              })
            }
          >
            <div className="w-12 h-12 pt-2">
              <DriverLicense />
            </div>
            <div className="font-medium">Driver&apos;s license</div>
          </div>
        </div>
        <div className="flex justify-center items-center gap-4">
          <div
            className="border border-grey-400 rounded-md p-4 h-32 flex items-center justify-center flex-col w-full cursor-pointer  max-w-[260px]"
            onClick={() =>
              handleDocumentUploadClick({
                value: DocumentUploadConstant.INTERNATIONAL_PASSPORT,
              })
            }
          >
            <div className="w-12 h-12">
              <Passport />
            </div>
            <div className="font-medium">{t("overview.intPass")}</div>
          </div>
          <div
            className="border border-grey-400 rounded-md p-4 h-32 flex items-center justify-center flex-col w-full cursor-pointer  max-w-[260px]"
            onClick={() =>
              handleDocumentUploadClick({
                value: DocumentUploadConstant.VOTERS_CARD,
              })
            }
          >
            <div className="w-12 h-12">
              <FilledCard />
            </div>
            <div className="font-medium">{t("overview.voterCard")}</div>
          </div>
        </div>

        <div
          className="text-green font-semibold w-full text-center cursor-pointer xl:mt-10 lg:mt-8 md:mt-4"
          onClick={() => setCurrentStep(1)}
        >
          {t("overview.dontHave")}
        </div>
      </div>
      {isShow ? (
        <Modal
          handleClose={handleDocumentUploadModalClose}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const StepOne = ({ setCurrentStep }) => {
  const [isAgreedTC, setIsAggreedTC] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="text-xl font-semibold">{t("overview.letVerify")}</div>
      <div className="text-grey-600 text-sm">{t("overview.verifyStory")}</div>
      <div className=" mt-9 font-medium">{t("overview.verifyDeclare")}</div>

      <div className="flex w-full gap-1 items-center mt-7">
        <input
          id="terms_conditions"
          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
          type="checkbox"
          onChange={(e) => setIsAggreedTC(e.target.checked)}
          value={isAgreedTC}
        />
        <label
          htmlFor={"terms_conditions"}
          className="text-md font-medium text-grey-800 cursor-pointer"
        >
          {t("overview.verifyAbove")}{" "}
          <span className="text-green">{t("overview.privacyPolicy")}</span>
        </label>
      </div>
      <div className="border px-5 py-4 rounded-md bg-green-50 border-green-100 flex items-center justify-between  mt-10">
        <div className="flex items-start gap-1  ">
          <InfoGrey className="text-green w-6 h-6 text-base" />
          <div className="font-medium  text-base text-green">
            {t("overview.startVerifyInfo")}
          </div>
        </div>
      </div>

      <div className=" w-full flex justify-center mt-9">
        <Button
          onClick={() => setCurrentStep(2)}
          type="button"
          border="border-green"
          extraClasses="max-w-[400px]"
          disabled={!isAgreedTC}
        >
          {t("overview.startVerification")}
        </Button>
      </div>
    </>
  );
};

export const KYC = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [documentData, setDocumentData] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();

  const {
    isSuccess,
    data: kycData,
    isLoading: loadingKYCData,
    isFetching: fetchingKYCData,
  } = useKYCData();

  useEffect(() => {
    if (searchParams) {
      if (searchParams.get("documentType")) {
        setDocumentType(searchParams.get("documentType"));
        setCurrentStep(2);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (state && Object.keys(state).length > 0) {
      setDocumentData(state);
    }
  }, [state]);

  const isLoadingKYCData = useMemo(
    () => loadingKYCData || fetchingKYCData,
    [loadingKYCData, fetchingKYCData]
  );

  if (isLoadingKYCData) return <Spinner />;

  if (isSuccess) {
    if (kycData && kycData.length > 0) {
      const isActiveKYC =
        kycData?.find((item) => item?.is_active !== 0) || null;
      const refKYCData = isActiveKYC;
      if (isActiveKYC && !state) {
        navigate(
          `/settings/customer-kyc/read-view?documentType=${refKYCData?.kyc_doc_type}`,
          {
            state: {
              ...refKYCData?.kyc_details,
              status: refKYCData?.status?.toLowerCase(),
              uuid: refKYCData?.uuid,
            },
          }
        );
        return;
      }
    }
  }

  return (
    <>
      <div className="overflow-auto w-full ">
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between mb-5">
            <div className="text-sm text-grey-700">
              {t("overview.manageKyc")}
            </div>
          </div>

          <div className="w-full rounded-md md:p-3 lg:p-5 xl:p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_226px)] flex justify-center">
            <div className="max-w-[800px] w-full flex flex-col gap-2">
              {currentStep === 1 ? (
                <StepOne setCurrentStep={setCurrentStep} />
              ) : (
                <StepTwo
                  setCurrentStep={setCurrentStep}
                  documentType={documentType}
                  documentData={documentData}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
