import { routes } from "./routes";
import { apiClient } from "./axios";

export const login = (value) => {
  return apiClient({
    method: routes.LOGIN.METHOD,
    url: routes.LOGIN.URL,
    data: value,
  });
};
export const sendOtp = (value) => {
  return apiClient({
    method: routes.SEND_OTP.METHOD,
    url: routes.SEND_OTP.URL,
    data: value,
  });
};
export const validate = (value) => {
  return apiClient({
    method: routes.VALIDATE.METHOD,
    url: routes.VALIDATE.URL,
    data: value,
  });
};
export const userDetails = (value) => {
  return apiClient({
    method: routes.USER_DETAILS.METHOD,
    url: routes.USER_DETAILS.URL,
    data: value,
  });
};
export const userDetailsByParams = (value) => {
  return apiClient({
    method: routes.USER_DETAILS_BY_PARAMS.METHOD,
    url: routes.USER_DETAILS_BY_PARAMS.URL,
    data: value,
  });
};
export const signUp = (value) => {
  return apiClient({
    method: routes.SIGNUP.METHOD,
    url: routes.SIGNUP.URL,
    data: value,
  });
};

export const userCredentials = () => {
  return apiClient({
    method: routes.USER_CREDENTIALS.METHOD,
    url: routes.USER_CREDENTIALS.URL,
  });
};

export const getDIDListing = (value) => {
  return apiClient({
    method: routes.DID_LISTING.METHOD,
    url: routes.DID_LISTING.URL,
    data: value,
  });
};

export const createContact = (value) => {
  return apiClient({
    method: routes.CREATE_CONTACT.METHOD,
    url: routes.CREATE_CONTACT.URL,
    data: value,
  });
};

export const createContactWithoutBatch = (value) => {
  return apiClient({
    method: routes.CREATE_CONTACT_WITHOUT_BATCH.METHOD,
    url: routes.CREATE_CONTACT_WITHOUT_BATCH.URL,
    data: value,
  });
};
export const importContact = (value) => {
  return apiClient({
    method: routes.IMPORT_CONTACT.METHOD,
    url: routes.IMPORT_CONTACT.URL,
    data: value,
  });
};

export const deleteContact = (value) => {
  return apiClient({
    method: routes.DELETE_CONTACT.METHOD,
    url: routes.DELETE_CONTACT.URL,
    data: value,
  });
};
export const deleteContactWithoutBatch = (value) => {
  return apiClient({
    method: routes.DELETE_CONTACT_WITHOUT_BATCH.METHOD,
    url: routes.DELETE_CONTACT_WITHOUT_BATCH.URL,
    data: value,
  });
};
export const deleteKnowledgeBase = (value) => {
  return apiClient({
    method: routes.KNOWLEDGEBASE_DELETE.METHOD,
    url: routes.KNOWLEDGEBASE_DELETE.URL,
    data: value,
  });
};
export const deleteFileInKnowledgeBase = (value) => {
  return apiClient({
    method: routes.KNOWLEDGEBASE_DELETE_FILE.METHOD,
    url: routes.KNOWLEDGEBASE_DELETE_FILE.URL,
    data: value,
  });
};
export const getMemberListing = (value) => {
  return apiClient({
    method: routes.MEMBER_LISTING.METHOD,
    url: routes.MEMBER_LISTING.URL,
    data: value,
  });
};
export const memberSignup = (value) => {
  return apiClient({
    method: routes.MEMBER_SIGNUP.METHOD,
    url: routes.MEMBER_SIGNUP.URL,
    data: value,
  });
};
export const memberUpdate = (value) => {
  return apiClient({
    method: routes.MEMBER_UPDATE.METHOD,
    url: routes.MEMBER_UPDATE.URL,
    data: value,
  });
};
export const memberDelete = (value) => {
  return apiClient({
    method: routes.MEMBER_DELETE.METHOD,
    url: routes.MEMBER_DELETE.URL,
    data: value,
  });
};
export const setDefaultDid = (value) => {
  return apiClient({
    method: routes.SET_DEFAULT_DID.METHOD,
    url: routes.SET_DEFAULT_DID.URL,
    data: value,
  });
};
export const assignDID = (value) => {
  return apiClient({
    method: routes.ASSIGN_DID.METHOD,
    url: routes.ASSIGN_DID.URL,
    data: value,
  });
};
export const assignTeam = (value) => {
  return apiClient({
    method: routes.ASSIGN_TEAM.METHOD,
    url: routes.ASSIGN_TEAM.URL,
    data: value,
  });
};
export const resendVerificationLink = (value) => {
  return apiClient({
    method: routes.RESEND_VERIFICATION_LINK.METHOD,
    url: routes.RESEND_VERIFICATION_LINK.URL,
    data: value,
  });
};
export const verifyPhone = (value) => {
  return apiClient({
    method: routes.VERIFY_PHONE.METHOD,
    url: routes.VERIFY_PHONE.URL,
    data: value,
  });
};
export const verifyEmail = (value) => {
  return apiClient({
    method: routes.VERIFY_EMAIL.METHOD,
    url: routes.VERIFY_EMAIL.URL,
    data: value,
  });
};
export const teamListing = (value) => {
  return apiClient({
    method: routes.TEAM_LISTING.METHOD,
    url: routes.TEAM_LISTING.URL,
    data: value,
  });
};
export const createTeam = (value) => {
  return apiClient({
    method: routes.CREATE_TEAM.METHOD,
    url: routes.CREATE_TEAM.URL,
    data: value,
  });
};
export const updateTeam = (value) => {
  return apiClient({
    method: routes.UPDATE_TEAM.METHOD,
    url: routes.UPDATE_TEAM.URL,
    data: value,
  });
};
export const deleteTeam = (value) => {
  return apiClient({
    method: routes.DELETE_TEAM.METHOD,
    url: routes.DELETE_TEAM.URL,
    data: value,
  });
};
export const assignUnassignMember = (value) => {
  return apiClient({
    method: routes.ASSIGN_UNASSIGN_MEMBER.METHOD,
    url: routes.ASSIGN_UNASSIGN_MEMBER.URL,
    data: value,
  });
};
export const assignUnassignMemberNumber = (value) => {
  return apiClient({
    method: routes.ASSIGN_UNASSIGN_MEMBER_NUMBER.METHOD,
    url: routes.ASSIGN_UNASSIGN_MEMBER_NUMBER.URL,
    data: value,
  });
};
export const getDIDMeta = (value) => {
  return apiClient({
    method: routes.DID_PURCHASE_META.METHOD,
    url: routes.DID_PURCHASE_META.URL,
    data: value,
  });
};
export const setForwarding = (value) => {
  return apiClient({
    method: routes.SET_FORWARDING.METHOD,
    url: routes.SET_FORWARDING.URL,
    data: value,
  });
};
export const removeForwarding = (value) => {
  return apiClient({
    method: routes.REMOVE_FORWARDING.METHOD,
    url: routes.REMOVE_FORWARDING.URL,
    data: value,
  });
};
export const setForwardingSettings = (value) => {
  return apiClient({
    method: routes.SET_FORWARDING_SETTINGS.METHOD,
    url: routes.SET_FORWARDING_SETTINGS.URL,
    data: value,
  });
};
export const buyDid = (value) => {
  return apiClient({
    method: routes.BUY_DID.METHOD,
    url: routes.BUY_DID.URL,
    data: value,
  });
};
export const releaseDID = (value) => {
  return apiClient({
    method: routes.RELEASE_DID.METHOD,
    url: routes.RELEASE_DID.URL,
    data: value,
  });
};

// Cart apis

export const addCartItem = (value) => {
  return apiClient({
    method: routes.ADD_CART_ITEM.METHOD,
    url: routes.ADD_CART_ITEM.URL,
    data: value,
  });
};
export const updateCartItem = (value) => {
  return apiClient({
    method: routes.UPDATE_CART_ITEM.METHOD,
    url: routes.UPDATE_CART_ITEM.URL,
    data: value,
  });
};

export const getCart = (value) => {
  return apiClient({
    method: routes.GET_CART.METHOD,
    url: routes.GET_CART.URL,
    data: value,
  });
};

export const removeCartItems = (value) => {
  return apiClient({
    method: routes.REMOVE_CART_ITEM.METHOD,
    url: routes.REMOVE_CART_ITEM.URL,
    data: value,
  });
};
export const greetingList = (value) => {
  return apiClient({
    method: routes.GREETING_LIST.METHOD,
    url: routes.GREETING_LIST.URL,
    data: value,
  });
};
export const createGreeting = (value) => {
  return apiClient({
    method: routes.CREATE_GREETING.METHOD,
    url: routes.CREATE_GREETING.URL,
    data: value,
  });
};
export const deleteGreeting = (value) => {
  return apiClient({
    method: routes.DELETE_GREETING.METHOD,
    url: routes.DELETE_GREETING.URL,
    data: value,
  });
};
export const deleteKYC = (value) => {
  return apiClient({
    method: routes.DELETE_KYC.METHOD,
    url: routes.DELETE_KYC.URL,
    data: value,
  });
};
export const updateGreeting = (value) => {
  return apiClient({
    method: routes.UPDATE_GREETING.METHOD,
    url: routes.UPDATE_GREETING.URL,
    data: value,
  });
};

export const uploadMedia = (value) => {
  return apiClient({
    method: routes.UPLOAD_MEDIA.METHOD,
    url: `${routes.UPLOAD_MEDIA.URL}`,
    data: value,
  });
};

export const callsHistory = (value) => {
  return apiClient({
    method: routes.CALLS_HISTORY.METHOD,
    url: `${routes.CALLS_HISTORY.URL}`,
    data: value,
  });
};
export const bulkCallSessions = (value) => {
  return apiClient({
    method: routes.BULK_CALL_SESSIONS.METHOD,
    url: `${routes.BULK_CALL_SESSIONS.URL}`,
    data: value,
  });
};
export const callSessionLogs = (value) => {
  return apiClient({
    method: routes.CALL_SESSION_LOGS.METHOD,
    url: `${routes.CALL_SESSION_LOGS.URL}`,
    data: value,
  });
};
export const createIVR = (value) => {
  return apiClient({
    method: routes.CREATE_IVR.METHOD,
    url: `${routes.CREATE_IVR.URL}`,
    data: value,
  });
};
export const updateIVR = (value) => {
  return apiClient({
    method: routes.UPDATE_IVR.METHOD,
    url: `${routes.UPDATE_IVR.URL}`,
    data: value,
  });
};
export const deleteIVR = (value) => {
  return apiClient({
    method: routes.DELETE_IVR.METHOD,
    url: `${routes.DELETE_IVR.URL}`,
    data: value,
  });
};
export const getIVRList = (value) => {
  return apiClient({
    method: routes.IVR_LIST.METHOD,
    url: `${routes.IVR_LIST.URL}`,
    data: value,
  });
};
export const bulkDelete = (value) => {
  return apiClient({
    method: routes.BULK_DELETE.METHOD,
    url: `${routes.BULK_DELETE.URL}`,
    data: value,
  });
};
export const bulkDeleteSession = (value) => {
  return apiClient({
    method: routes.BULK_DELETE_SESSION.METHOD,
    url: `${routes.BULK_DELETE_SESSION.URL}`,
    data: value,
  });
};
export const getDashboardStats = (value) => {
  return apiClient({
    method: routes.DASHBOARD_STATS.METHOD,
    url: `${routes.DASHBOARD_STATS.URL}`,
    data: value,
  });
};
export const forgotPassword = (value) => {
  return apiClient({
    method: routes.FORGOT_PASSWORD.METHOD,
    url: `${routes.FORGOT_PASSWORD.URL}`,
    data: value,
  });
};
export const profileUpadte = (value) => {
  return apiClient({
    method: routes.UPDATE_PROFILE.METHOD,
    url: `${routes.UPDATE_PROFILE.URL}`,
    data: value,
  });
};
export const getVerificationStatus = (value) => {
  return apiClient({
    method: routes.GET_VERIFICATION_STATUS.METHOD,
    url: `${routes.GET_VERIFICATION_STATUS.URL}`,
    data: value,
  });
};
export const makePayment = (value) => {
  return apiClient({
    method: routes.MAKE_PAYMENT.METHOD,
    url: `${routes.MAKE_PAYMENT.URL}`,
    data: value,
  });
};
export const paymentVerification = (value) => {
  return apiClient({
    method: routes.VERIFY_PAYMENT.METHOD,
    url: `${routes.VERIFY_PAYMENT.URL}`,
    data: value,
  });
};
export const depositVerification = (value) => {
  return apiClient({
    method: routes.VERIFY_DEPOSIT.METHOD,
    url: `${routes.VERIFY_DEPOSIT.URL}`,
    data: value,
  });
};
export const fundVerification = (value) => {
  return apiClient({
    method: routes.VERIFY_FUND.METHOD,
    url: `${routes.VERIFY_FUND.URL}`,
    data: value,
  });
};
export const getCompanyDetails = (value) => {
  return apiClient({
    method: routes.GET_COMPANY_DETAILS.METHOD,
    url: `${routes.GET_COMPANY_DETAILS.URL}`,
    data: value,
  });
};
export const updateCompanyDetails = (value) => {
  return apiClient({
    method: routes.UPDATE_COMPANY_DETAILS.METHOD,
    url: `${routes.UPDATE_COMPANY_DETAILS.URL}`,
    data: value,
  });
};
export const getPlans = (value) => {
  return apiClient({
    method: routes.GET_PLANS.METHOD,
    url: `${routes.GET_PLANS.URL}`,
    data: value,
  });
};
export const changePlan = (value) => {
  return apiClient({
    method: routes.CHANGE_PLAN.METHOD,
    url: `${routes.CHANGE_PLAN.URL}`,
    data: value,
  });
};
export const buyBundle = (value) => {
  return apiClient({
    method: routes.BUNDLE_CHECKOUT.METHOD,
    url: `${routes.BUNDLE_CHECKOUT.URL}`,
    data: value,
  });
};
export const addKYC = (value) => {
  return apiClient({
    method: routes.ADD_KYC.METHOD,
    url: `${routes.ADD_KYC.URL}`,
    data: value,
  });
};
export const updateKYC = (value) => {
  return apiClient({
    method: routes.UPDATE_KYC.METHOD,
    url: `${routes.UPDATE_KYC.URL}`,
    data: value,
  });
};
export const kycData = (value) => {
  return apiClient({
    method: routes.KYC_LIST.METHOD,
    url: `${routes.KYC_LIST.URL}`,
    data: value,
  });
};
export const bundleListing = (value) => {
  return apiClient({
    method: routes.BUNDLE_LIST.METHOD,
    url: `${routes.BUNDLE_LIST.URL}`,
    data: value,
  });
};
export const myBundleList = (value) => {
  return apiClient({
    method: routes.MY_BUNDLE_LIST.METHOD,
    url: `${routes.MY_BUNDLE_LIST.URL}`,
    data: value,
  });
};

export const getSavedCardsList = (value) => {
  return apiClient({
    method: routes.GET_SAVED_CARDS.METHOD,
    url: `${routes.GET_SAVED_CARDS.URL}`,
    data: value,
  });
};
export const setDefaultCard = (value) => {
  return apiClient({
    method: routes.SET_DEFAULT_CARD.METHOD,
    url: `${routes.SET_DEFAULT_CARD.URL}`,
    data: value,
  });
};
export const deleteCard = (value) => {
  return apiClient({
    method: routes.DELETE_CARD.METHOD,
    url: `${routes.DELETE_CARD.URL}`,
    data: value,
  });
};
export const getWalletAmounts = (value) => {
  return apiClient({
    method: routes.GET_WALLET_AMOUNT.METHOD,
    url: `${routes.GET_WALLET_AMOUNT.URL}`,
    data: value,
  });
};
export const getTransactionList = (value) => {
  return apiClient({
    method: routes.TRANSACTION_LIST.METHOD,
    url: `${routes.TRANSACTION_LIST.URL}`,
    data: value,
  });
};
export const checkoutWallet = (value) => {
  return apiClient({
    method: routes.CHECKOUT_WALLET.METHOD,
    url: `${routes.CHECKOUT_WALLET.URL}`,
    data: value,
  });
};
export const walletVerifyPayment = (value) => {
  return apiClient({
    method: routes.WALLET_VERIFY_PAYMENT.METHOD,
    url: `${routes.WALLET_VERIFY_PAYMENT.URL}`,
    data: value,
  });
};
export const walletCheckoutDeposit = (value) => {
  return apiClient({
    method: routes.WALLET_CHECKOUT_DEPOSIT.METHOD,
    url: `${routes.WALLET_CHECKOUT_DEPOSIT.URL}`,
    data: value,
  });
};
export const walletDetails = (value) => {
  return apiClient({
    method: routes.WALLET_DETAILS.METHOD,
    url: `${routes.WALLET_DETAILS.URL}`,
    data: value,
  });
};
export const walletAutoTopup = (value) => {
  return apiClient({
    method: routes.WALLET_AUTO_TOPUP.METHOD,
    url: `${routes.WALLET_AUTO_TOPUP.URL}`,
    data: value,
  });
};
export const requestPlan = (value) => {
  return apiClient({
    method: routes.REQUEST_PLAN.METHOD,
    url: `${routes.REQUEST_PLAN.URL}`,
    data: value,
  });
};
export const getRequestedPlan = (value) => {
  return apiClient({
    method: routes.GET_REQUESTED_PLAN.METHOD,
    url: `${routes.GET_REQUESTED_PLAN.URL}`,
    data: value,
  });
};
export const cancelRequestedPlan = (value) => {
  return apiClient({
    method: routes.CANCEL_REQUEST.METHOD,
    url: `${routes.CANCEL_REQUEST.URL}`,
    data: value,
  });
};
export const knowledgeBaseList = (value) => {
  return apiClient({
    method: routes.KNOWLEDGEBASE_LIST.METHOD,
    url: `${routes.KNOWLEDGEBASE_LIST.URL}`,
    data: value,
  });
};
export const contactLists = (value) => {
  return apiClient({
    method: routes.LIST_CONTACTS.METHOD,
    url: `${routes.LIST_CONTACTS.URL}`,
    data: value,
  });
};
export const contactListsWithoutBatch = (value) => {
  return apiClient({
    method: routes.LIST_CONTACTS_WITHOUT_BATCH.METHOD,
    url: `${routes.LIST_CONTACTS_WITHOUT_BATCH.URL}`,
    data: value,
  });
};
export const updateContactList = (value) => {
  return apiClient({
    method: routes.UPDATE_CONTACT.METHOD,
    url: `${routes.UPDATE_CONTACT.URL}`,
    data: value,
  });
};
export const updateContactListWithoutBatch = (value) => {
  return apiClient({
    method: routes.UPDATE_CONTACT_WITHOUT_BATCH.METHOD,
    url: `${routes.UPDATE_CONTACT_WITHOUT_BATCH.URL}`,
    data: value,
  });
};
export const updateKnowledgeBaseList = (value) => {
  return apiClient({
    method: routes.KNOWLEDGEBASE_UPDATE.METHOD,
    url: `${routes.KNOWLEDGEBASE_UPDATE.URL}`,
    data: value,
  });
};
export const batchLists = (value) => {
  return apiClient({
    method: routes.LIST_BATCH.METHOD,
    url: `${routes.LIST_BATCH.URL}`,
    data: value,
  });
};
export const createBatch = (value) => {
  return apiClient({
    method: routes.CREATE_BATCH.METHOD,
    url: `${routes.CREATE_BATCH.URL}`,
    data: value,
  });
};
export const getAllBatches = (value) => {
  return apiClient({
    method: routes.GET_ALL_BATCHES.METHOD,
    url: `${routes.GET_ALL_BATCHES.URL}`,
    data: value,
  });
};
export const deleteBatch = (value) => {
  return apiClient({
    method: routes.DELETE_BATCH.METHOD,
    url: routes.DELETE_BATCH.URL,
    data: value,
  });
};
