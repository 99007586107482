import {
  BotsTree,
  Coffee,
  FacebookMessenger,
  InstagramMessenger,
  LinkedIn,
  NoRecord,
  Omni,
  Plus,
  SmsIcon,
  TelegramIcon,
  Twitter,
  WhatsappBusiness,
} from "assets/images";
import { Button, Input } from "components";
import {
  ChevronLeft,
  ChevronRight,
  Copy,
  Edit,
  Eye,
  MoreVertical,
  PenLine,
  Trash2,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SocialItem = ({ item, onClick }) => (
  <div
    className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100"
    onClick={() => onClick(item.path)}
  >
    <item.icon className="w-6 h-6" />
    <span>{item.name}</span>
  </div>
);

const AllBots = () => {
  const socials = [
    {
      id: "whatsapp",
      path: "/settings/whatsapp",
      name: "WhatsApp Business",
      icon: WhatsappBusiness,
    },
    {
      id: "telegram",
      path: "/settings/telegram",
      name: "Telegram Messenger",
      icon: TelegramIcon,
    },
    {
      id: "instagram",
      path: "/settings/instagram",
      name: "Instagram Messenger",
      icon: InstagramMessenger,
    },
    {
      id: "facebook",
      path: "/settings/facebook",
      name: "Facebook Messenger",
      icon: FacebookMessenger,
    },
    {
      id: "twitter",
      path: "/settings/twitter",
      name: "Twitter DM",
      icon: Twitter,
    },
    {
      id: "linkedin",
      path: "/settings/linkedin",
      name: "LinkedIn Messaging",
      icon: LinkedIn,
    },
    {
      id: "sms-socials",
      path: "/settings/sms-socials",
      name: "SMS",
      icon: SmsIcon,
    },
  ];

  const [showItems, setShowItems] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  const handleDropdownToggle = () => setShowItems((prev) => !prev);
  const handleOptionsToggle = () => setShowOptions((prev) => !prev);

  const [whatsappConfig] = useState(
    localStorage.getItem("whatsapp")
      ? JSON.parse(localStorage.getItem("whatsapp"))
      : null
  );

  const handleItemClick = (path) => {
    if (path.includes("whatsapp") && whatsappConfig && whatsappConfig.linked) {
      navigate("/settings/whatsapp?step=2");
    } else {
      navigate(path);
    }
    setShowItems(false);
  };

  const EmptyBot = () => {
    return (
      <div className="flex flex-col gap-6 justify-center items-center h-[200px] m-auto mt-20 w-[338px]">
        <NoRecord />
        <p className="mt-4 text-center text-black/60 text-[16px]">
          No bots found
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className="flex w-full justify-between">
        <div className="w-[400px]">
          <Input withSearchIcon extraClasses="pl-10" type="search" />
        </div>
        <div className="w-[300px]">
          <Button onClick={handleDropdownToggle}>
            <Plus />
            <span>Add New Bot</span>
          </Button>
        </div>
      </div>
      {showItems && (
        <div className="absolute bg-white right-6 rounded shadow-md mt-2 w-[300px] z-10">
          {socials.map((item) => (
            <SocialItem key={item.id} item={item} onClick={handleItemClick} />
          ))}
        </div>
      )}

      {!whatsappConfig?.bots ? (
        <EmptyBot />
      ) : (
        whatsappConfig?.bots?.map((bot, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                navigate("/settings/flow");
              }}
            >
              <div className="mt-8">
                <div className="flex flex-col w-[338px] relative">
                  <div className="h-[200px] bg-[#D2D5DA] hover:bg-[#D2D5DA]/50 cursor-pointer w-[338px] items-center flex justify-center rounded-t-lg">
                    <BotsTree />
                  </div>
                  <div className="h-[44px] bg-[#0D440DCC] w-[338px]" />
                  <div className="bg-[#F1F1F1] h-[62px] w-[338px] flex items-center justify-between rounded-b-lg px-3">
                    <div className="flex gap-4 items-center">
                      <Omni color="#084FA0" />
                      <Coffee />
                      <p className="font-bold text-[12px]">{bot}</p>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={handleOptionsToggle}
                    >
                      <MoreVertical size={15} />
                    </div>
                    {showOptions && (
                      <div className="absolute py- flex flex-col justify-center items-center gap-2 text-base top-4 h-[231px] right-2 bg-white rounded shadow-md mt-2 w-[240px] z-10">
                        {/* <div className=""> */}
                        <p className="text-blackß gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
                          <Eye size={18} />
                          <span>Open Flow</span>
                        </p>
                        <p className="text-black gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
                          <Edit size={18} />
                          Edit Flow
                        </p>
                        <p className="text-black gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
                          <Copy size={18} />
                          Copy Flow
                        </p>
                        <p className="text-black gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
                          <PenLine size={18} />
                          Rename
                        </p>
                        <p className="text-[#EF4444] gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
                          <Trash2 size={18} /> Delete
                        </p>
                        {/* </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex gap-4 items-center mr-auto justify-end mt-12">
                <p>Total 1</p>
                <div className="flex gap-2 items-center">
                  <button
                    disabled
                    className="w-6 h-6 bg-[#F1F1F1] flex items-center justify-center"
                  >
                    <ChevronLeft size={14} />
                  </button>
                  <p className="bg-green-400 flex items-center justify-center w-6 h-6">
                    1
                  </p>
                  <button
                    disabled
                    className="w-6 h-6 bg-[#F1F1F1] flex items-center justify-center"
                  >
                    <ChevronRight size={14} />
                  </button>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
export default AllBots;
