import { useMutation } from "@tanstack/react-query";
import { deleteKnowledgeBase } from "api";
import { toastEmitter } from "components/Toast";

export default function useDeleteKnowledgeBase({
  handleSuccess = () => null,
  handleError = () => null,
} = {}) {
  const Request = useMutation({
    mutationFn: deleteKnowledgeBase,
    mutationKey: ["deleteKnowledgeBase"],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        handleError();
        return toastEmitter("error", responseData?.error?.message);
      }
      handleSuccess();

      toastEmitter("success", responseData?.data?.message);
    },
  });
  return { ...Request };
}
