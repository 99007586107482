import { useMutation } from "@tanstack/react-query";
import { knowledgeBaseList } from "api";

export default function useGetKnowledgeBaseList() {
  const knowledgeBaseListRequest = useMutation({
    mutationFn: knowledgeBaseList,
    mutationKey: ["knowledgeBaseList"],
  });
  return { ...knowledgeBaseListRequest };
}
